import React, { useState, useEffect } from 'react';
import axios from 'axios';

const fetchTagData = async (rid) => {
  let url = "";
  if(process.env.NODE_ENV === 'development') 
    url = `http://localhost:80/php/tag_info.php?q=${rid.tag}`;
  else
    url = `https://vnlike.org/php/tag_info.php?q=${rid.tag}`;
  const response = await axios.get(url);
  return response.data;
};

const Tag = ({ data, vote, spoiler }) => {
  if (!data || spoiler > 0 || vote <= 0 || data.defaultspoil || data.cat == 'ero')
    return null;

  return (
    <div className='tag'>
      <span>{data.name} ({Math.round(vote * 10.0)/10.0})</span>
    </div>
  );
};


const Tags = ({vid, vnTagData}) => {
  const [tagData, setTagData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const allReleaseData = await Promise.all(vnTagData.map(rid => fetchTagData(rid)));
      setTagData(allReleaseData);
    };
    
    if (vnTagData) {
      fetchData();
    }
  }, [vnTagData]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }} className='tag-container'>
      {tagData &&
        tagData
          .map((release, index) => ({
            release,
            vote: vnTagData[index].vote,
            spoiler: vnTagData[index].spoiler
          }))
          .sort((a, b) => b.vote - a.vote)
          .map(({ release, vote, spoiler }, index) => (
            <Tag key={index} data={release} vote={vote} spoiler={spoiler} />
          ))}
    </div>
  );
  
  
};

export default Tags;