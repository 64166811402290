import React, { useState, useEffect } from 'react';
import axios from 'axios';

const fetchReleaseData = async (rid) => {
  let url = "";
  if(process.env.NODE_ENV === 'development')
    url = `http://localhost:80/php/release_info.php?q=${rid.id}`;
  else
    url = `https://vnlike.org/php/release_info.php?q=${rid.id}`;
  const response = await axios.get(url);
  return response.data;
};

const fetchReleaseTitle = async (rid) => {
  let url = "";
  if(process.env.NODE_ENV === 'development')
    url = `http://localhost:80/php/release_title.php?q=${rid.id}`;
  else
    url = `https://vnlike.org/php/release_title.php?q=${rid.id}`;
  const response = await axios.get(url);
  return response.data;
};

const getOlangEmoji = (olang) => {
  const emojiMap = {
    'ja': '🇯🇵',
    'en': '🇺🇸',
    'zh-Hans': '🇨🇳',
    'de': '🇩🇪',
    'pt-br' : '🇧🇷',
    'es': '🇪🇸',
    'fr': '🇫🇷',
    'ru': '🇷🇺',
    'it': '🇮🇹',
    'pl': '🇵🇱',
    'ko': '🇰🇷',
    'zh-Hant': '🇹🇼',
    'vi': '🇻🇳',
    'th': '🇹🇭',
    'cs': '🇨🇿',
    'hu': '🇭🇺',
    'ro': '🇷🇴',
    'nl': '🇳🇱',
    'sv': '🇸🇪',
    'uk': '🇺🇦',
    'id': '🇮🇩',
    'tr': '🇹🇷',
    'fi': '🇫🇮',
    'bg': '🇧🇬',
    'da': '🇩🇰',
    'ar': '🇸🇦',
    'fa': '🇮🇷',
    'el': '🇬🇷',
    'no': '🇳🇴',
    'ms': '🇲🇾',
    'sr': '🇷🇸',
  };
  
  return emojiMap[olang] || olang;
};

const getReleaseDate = (released) => {
  if(released == 99999999)
    return "TBA";
  if (typeof released === 'string') {
    return `${released.substring(0, 4)}-${released.substring(4, 6)}-${released.substring(6, 8)}`;
  } else if (typeof released === 'number') {
    const strReleased = String(released);
    return `${strReleased.substring(0, 4)}-${strReleased.substring(4, 6)}-${strReleased.substring(6, 8)}`;
  }
  return '';
};

const getTagColor = (type, released, mtl) => {
  switch(type)
  {
    case 'complete':
      if(released == 99999999)
        return 'orange';
      if(mtl)
        return 'darkgreen';
      return 'green';
    case 'partial':
      return 'purple';
    case 'trial':
      return 'grey';
  }

  return 'grey';
};

const Release = ({ data, type, releaseTitle }) => (
  <div display='grid' className='tag' style={{backgroundColor: getTagColor(type, data.released)}}>
    <span>  <b>{getReleaseDate(data.released)}</b> - {releaseTitle.title}</span>
  </div>
);

const Releases = ({vid, vnReleasesData}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [releaseData, setReleaseData] = useState([]);
  const [releaseTitles, setReleaseTitles] = useState([]);
  const [uniqueReleaseTypes, setUniqueReleaseTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const allReleaseData = await Promise.all(vnReleasesData.map(rid => fetchReleaseData(rid)));
      const allTitleData = await Promise.all(vnReleasesData.map(rid => fetchReleaseTitle(rid)));

      // Structure data into an object where the keys are the unique release types and the values are arrays of releases of that type
      const structuredData = allReleaseData.reduce((acc, release) => {
        if (!acc[release.olang]) {
          acc[release.olang] = [];
        }
        acc[release.olang].push(release);
        return acc;
      }, {});

      setReleaseData(structuredData);
      setReleaseTitles(allTitleData);
      
      // Get unique release types
      const uniqueTypes = [...new Set(allReleaseData.map(data => data.olang))];
      setUniqueReleaseTypes(uniqueTypes);
    };
    
    if (vnReleasesData) {
      fetchData();
    }
  }, [vnReleasesData]);

  const getReleasesLength = () => {
    if (vnReleasesData == null)
      return 0;
    return vnReleasesData.length;
  };

  return (
    <div>
      <button 
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        color: 'rgb(237, 244, 255)',
        textDecoration: 'underline',
        cursor: 'pointer'
      }} 
        onClick={() => setIsExpanded(!isExpanded)}>
        {uniqueReleaseTypes.map(type => getOlangEmoji(type)).join(' ')} - {getReleasesLength()} release{getReleasesLength()>1?'s':''}
      </button>
      {isExpanded && Object.entries(releaseData).map(([type, releases]) => (
        <div key={type} className='tag-container'>
          <h3>{getOlangEmoji(type)}</h3>
          {releases && releases.map((release, index) => (
            <Release key={index} data={release} type={vnReleasesData[index].rtype} releaseTitle={releaseTitles[index]} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Releases;