import React, { useState, useEffect } from 'react';
import axios from 'axios';


const imgData = async (imageString) => {
  let url = "";
  if(process.env.NODE_ENV === 'development')
    url = `http://localhost:80/php/img_info.php?q=${imageString}`;
  else
    url = `https://vnlike.org/php/img_info.php?q=${imageString}`;
  const response = await axios.get(url);
  return response.data;
};

const ImageComponent = ({ imageString, style }) => {
  const [imageData, setImageData] = useState({});
  const [isBlurred, setIsBlurred] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
        setIsBlurred(true);
        if(imageString)
        {
            const id = imageString.slice(2);
            const data = await imgData(imageString);
            setImageData(data);
            setIsBlurred(data.c_sexual_avg > 0 || imageData.c_sexual_stddev > 0 || data.c_violence_avg > 0 || data.c_violence_stddev > 0);
            setIsLoaded(true);
        }
    };

    fetchImage();
  }, [imageString]);

  const handleClick = () => {
    if (isBlurred) setIsBlurred(false);
  };

  function getImagePath() {
    if (imageString === null) {
        return null;
    }

    const subFolder = imageString.slice(-2);
    let url = "";
    if(process.env.NODE_ENV === 'development') 
      url = `http://localhost:80/vndb-img/cv/${subFolder}/${imageString.slice(2)}.jpg`;
    else
      url = `https://vnlike.org/vndb-img/cv/${subFolder}/${imageString.slice(2)}.jpg`;
    return url;
}

  const blurClass = isBlurred ? 'blur' : '';
  const darkenClass = isBlurred ? 'darken' : '';

  const nsfwText = () => {
    if(!isLoaded) return null;
    let sexual = Math.max(imageData.c_sexual_avg, imageData.c_sexual_stddev);
    let violence = Math.max(imageData.c_violence_avg, imageData.c_violence_stddev);

    if (sexual >= 100) {
      if(violence >= 100)
        return <p className="nsfw-text">Image is<br /><b style={{ color: 'red' }}>sexually explicit</b><br />and<br /><b style={{ color: 'red' }}>extremely violent</b></p>
      else if(violence > 0)
        return <p className="nsfw-text">Image is<br /><b style={{ color: 'red' }}>sexually explicit</b><br />and<br /><b>violent</b></p>
      return <p className="nsfw-text">Image is<br /><b style={{ color: 'red' }}>sexually explicit</b></p>
    }
    else if (sexual > 0) 
    {
      if(violence >= 100)
        return <p className="nsfw-text">Image is<br /><b>sexually suggestive</b><br />and<br /><b style={{ color: 'red' }}>extremely violent</b></p>
      else if(violence > 0)
        return <p className="nsfw-text">Image is<br /><b>sexually suggestive</b><br />and<br /><b>violent</b></p>
      return <p className="nsfw-text">Image is<br/><b>sexually suggestive.</b></p>
    }
    else
    {
      if(violence >= 100)
        return <p className="nsfw-text">Image is<br /><b>violent</b></p>
      else if(violence > 0)
        return <p className="nsfw-text">Image is<br /><b>violent</b></p>
    }
    return null;
  };

  return (
    <div style={style} onClick={handleClick}>
        <div className={`nsfw-container ${darkenClass}`}>
        {isBlurred && nsfwText()}
        <img
            className={`image ${blurClass}`}
            src={getImagePath()} alt="Special" />
        </div>
    </div>
  );
};

export default ImageComponent;
