// src/components/Footer.js

import React from 'react';

const Footer = () => {
    return (
        <footer>
          <br /><br />
            This website uses data from <a href="https://vndb.org/">vndb.org</a>. You can view its <a href="https://vndb.org/d17#4">license (ODbL) here</a>.
            <br/>No information is stored or collected at any time.
            <br/>View this project on <a href="https://github.com/JuicyStandoffishMan/VisualNovelRecommendationEngine">GitHub</a> to get more detailed recommendations or get the adapted MySQL databases.
        </footer>
    );
}

export default Footer;
