import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; // install axios for http requests
import bbobHTML from '@bbob/html'
import ImageComponent from './img';
import Releases from './releases';
import Tags from './tags';

function VisualNovelTitle({ id, title, vnid }) {
    const [hovered, setHovered] = useState(false);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(null);
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
    const hoverTimeout = useRef(null);
    const [tooltipMaxHeight, setTooltipMaxHeight] = useState(650);
    const tooltipRef = useRef(null);

    const [producerData, setProducer] = useState(null);
    const [vnReleasesData, setVnReleases] = useState(null);
    const [lengthData, setLength] = useState(null);
    const [vnTagData, setVnTagData] = useState(null);

    const fetchData = async () => {
        let url = "";
        if(process.env.NODE_ENV === 'development')
          url = `http://localhost:80/php/vn_info.php?q=${vnid}`;
        else
          url = `https://vnlike.org/php/vn_info.php?q=${vnid}`;
        const response = await axios.get(url);
        setData(response.data);
        
        if(process.env.NODE_ENV === 'development')
          url = `http://localhost:80/php/producer_info.php?q=${vnid}`;
        else
          url = `https://vnlike.org/php/producer_info.php?q=${vnid}`;
        const producer_response = await axios.get(url);
        setProducer(producer_response.data);

        if(process.env.NODE_ENV === 'development') 
          url = `http://localhost:80/php/length_info.php?q=${vnid}`;
        else
          url = `https://vnlike.org/php/length_info.php?q=${vnid}`;
        const length_response = await axios.get(url);
        setLength(length_response.data);
        
        if(process.env.NODE_ENV === 'development')
          url = `http://localhost:80/php/releases_vn.php?q=${vnid}`;
        else
          url = `https://vnlike.org/php/releases_vn.php?q=${vnid}`;
        const vn_releases_response = await axios.get(url);
        setVnReleases(vn_releases_response.data);

        if(process.env.NODE_ENV === 'development')
          url = `http://localhost:80/php/tags_vn.php?q=${vnid}`;
        else
          url = `https://vnlike.org/php/tags_vn.php?q=${vnid}`;
        const tag_response = await axios.get(url);
        setVnTagData(tag_response.data);
    };

    useEffect(() => {
        if (hovered) {
            hoverTimeout.current = setTimeout(() => {
              if(!visible)
              {
                fetchData();
                setVisible(true);
              }
            }, 600); // 1 second delay
        } else {
            clearTimeout(hoverTimeout.current);
            setData(null); // reset data when not hovered
            setVisible(false);
        }
    }, [hovered, id]);

    useEffect(() => {
      if (tooltipRef.current) {
          // Adjust the maximum height to be the maximum of the actual tooltip height and the initial maximum height
          setTooltipMaxHeight(tooltipRef.current.getBoundingClientRect().height);
      }
  }, [data]);

    const handleMouseMove = (e) => {
      if(visible) return;
      setMousePosition({x: e.clientX, y: e.clientY});
      setTooltipMaxHeight(Math.max(650, window.innerHeight - e.clientY));
  };

    const getLengthText = () =>
    {
      if (data == null)
        return <i>Unknown length</i>;

        const len_map = {
          0: "Very short (< 2 hours)",
          1: "Short (2 - 10 hours)",
          2: "Medium (10 - 30 hours)",
          3: "Long (30 - 50 hours)",
          4: "Very long (> 50 hours)"
        };

        const finer_len_map = {
          120: "Very short",
          600: "Short",
          1800: "Medium",
          3000: "Long",
          1000000: "Very long"
        };

        const get_range = (len) => {
          for (const [key, value] of Object.entries(finer_len_map)) {
            if (len < key)
              return value;
          }
        }


        if(lengthData == null)
          return <span> {len_map[data.length]} </span>;
        
        if(lengthData.length < 60)
          return <span> {get_range(lengthData.length)} ({lengthData.length} minutes) </span>;
        else
          return <span> {get_range(lengthData.length)} ({Math.floor(lengthData.length / 60)} hour{Math.floor(lengthData.length / 60) > 1 ? 's' : ''}) </span>;
    }


    return (
      <div 
          key={id}
          className='tooltip-container'
          onMouseLeave={() => setHovered(false)}
          >
          <a href="javascript:void(0);"
          onMouseEnter={() => setHovered(true)}
          onMouseMove={handleMouseMove}
          onClick={() =>
          {
            fetchData();
            setVisible(true);
          } }>
              {title}
          </a>
          {hovered && data && (
              <div
                ref={tooltipRef}
                className="tooltip"
                style={{
                  left: `${Math.max(0, Math.min(mousePosition.x-4, window.innerWidth - 450))}px`, 
                  top: `${Math.min(mousePosition.y-4, window.innerHeight - tooltipMaxHeight - 30)}px`,
                  maxHeight: `${tooltipMaxHeight}px`
              }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', textAlign: 'left'}}>
                  <ImageComponent imageString={data.image}
                  style={{ 
                    maxWidth: '200px', 
                    maxHeight: '100%', 
                    objectFit: 'cover',
                    paddingRight: '10px'
                  }}/>
                  <div style={{  alignItems: 'left', textAlign: 'left'}}>
                    <h3>{title}</h3>
                    (<a href={`https://vndb.org/v${vnid}`} target="_blank" rel="noopener noreferrer">v{vnid}</a>)
                    <br />
                    {getLengthText()}
                    <br />
                    <Releases vid={vnid} vnReleasesData={vnReleasesData} />
                  </div>
                </div>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: data.description ? bbobHTML(data.description.replaceAll('\\n', '<br />')) : "<i>No description.</i>" }} />
                <hr />
                <div>
                  <Tags vid={vnid} vnTagData={vnTagData} />
                </div>
              </div>
          )}
      </div>
  );
  
}

export default VisualNovelTitle;
